import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppComponent } from './app.component';
import { UiModule } from '@iikigai/ui';
import { TFJSModule } from 'ngx-tfjs';
import { AppRoutingModule } from './app-routing.module';
import { OpenthreadDashboardComponent } from './openthread-dashboard/openthread-dashboard.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  NbThemeModule, NbLayoutModule, NbSidebarModule, NbButtonModule, NbCardModule, NbMenuModule, NbMenuService, NbToastrModule, NbDatepickerModule, NbIconModule, NbChatModule
} from '@nebular/theme';
import { NbEvaIconsModule } from '@nebular/eva-icons';
import { NbMenuInternalService } from '@nebular/theme/components/menu/menu.service';
import { ApiModule } from './api/api.module';
import { HttpClientModule } from '@angular/common/http';
import { OpenthreadDashboardModule } from './openthread-dashboard/openthread-dashboard.module';
import { Fit20Module } from './fit20/fit20.module';
import { NbPasswordAuthStrategy, NbAuthModule } from '@nebular/auth';

@NgModule({
  declarations: [AppComponent, OpenthreadDashboardComponent,],
  imports: [BrowserModule,
    UiModule,
    TFJSModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    NbThemeModule.forRoot({ name: 'default' }),
    NbLayoutModule,
    NbEvaIconsModule,
    NbSidebarModule.forRoot(),
    NbButtonModule,
    NbCardModule,
    NbMenuModule.forRoot(),
    ApiModule.forRoot({ rootUrl: 'http://localhost:8080' }),
    NbToastrModule.forRoot(),
    NbDatepickerModule.forRoot(),
    NbIconModule,
    NbLayoutModule,
    NbChatModule.forRoot(),
    OpenthreadDashboardModule,
    NbAuthModule.forRoot({
      strategies: [NbPasswordAuthStrategy.setup({
        name: 'email',
      }),
      ]
    }),

    // Fit20Module,
  ],
  providers: [

  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
