import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'iikigai-hello-iikigai',
  templateUrl: './hello-iikigai.component.html',
  styleUrls: ['./hello-iikigai.component.scss']
})
export class HelloIikigaiComponent implements OnInit {
  @Input() title: any;

  // tslint:disable-next-line: no-output-on-prefix
  @Output()
  onPinTask = new EventEmitter<Event>();

  // tslint:disable-next-line: no-output-on-prefix
  @Output()
  onArchiveTask = new EventEmitter<Event>();
  // title = 'Hello i-ikigai ';

  constructor() { }

  ngOnInit(): void {
  }

}
