<nb-layout>

  <nb-layout-header fixed>
    <!-- Insert header here -->
    <header class="ii-header">
      <img alt="IIKIGAI logo" width="75" src="./assets/iikigai-white-logo.png" />
      <!-- <h1>Welcome!</h1> -->
    </header>
  </nb-layout-header>
  <nb-sidebar state="expanded" tag="main">
    <button nbButton ghost status="danger" (click)="toggleMainSidebar()" style="margin-left: -10px;">
      <nb-icon icon="menu"></nb-icon>
    </button>

    <nb-menu [items]="menu">
    </nb-menu>

  </nb-sidebar>

  <nb-layout-column class="colored-column-danger" style="padding: 0px;">
    <router-outlet></router-outlet>
  </nb-layout-column>


  <nb-layout-footer fixed>
    <!-- Insert footer here -->
  </nb-layout-footer>

</nb-layout>