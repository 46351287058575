import { NbMenuItem } from "@nebular/theme";

export const MENU_ITEMS: NbMenuItem[] = [
    {
        title: 'openThread',
        expanded: true,
        icon: 'wifi-outline',
        badge: {
            text: '+',
            status: 'primary',
        },
        link: '/openthread',
        children: [
            {
                title: 'Devices',
                badge: {
                    text: '99+',
                    status: 'danger',
                },
                link: '/openthread/devices',
            },
            {
                title: 'Events',
                badge: {
                    dotMode: true,
                    status: 'warning',
                },
                link: 'openthread/events'
            },
            {
                title: 'Firmware',
                badge: {
                    text: 'new',
                    status: 'success',
                },
                hidden: true,
            },
        ],
    },

    {
        title: 'fit20',
        icon: 'heart-outline',
        expanded: false,
        children: [
            {
                title: 'Simulator',
                icon: 'heart-outline',
                link: '/fit20/simulator',
            },
            {
                title: 'Scraper',
                icon: 'grid-outline',
                link: '/fit20/home',
            },
            {
                title: 'Analytics',
                icon: 'info',
                link: '/fit20/report',
            },
            {
                title: 'Runner',
                icon: 'list',
                link: '/fit20/runner',
            },

        ],

    },
];
