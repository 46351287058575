import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'iikigai-heart-rate',
  templateUrl: './heart-rate.component.html',
  styleUrls: ['./heart-rate.component.scss']
})
export class HeartRateComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
