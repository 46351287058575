import { Component } from '@angular/core';
import { NbSidebarService } from '@nebular/theme';

import { MENU_ITEMS } from './menu';
@Component({
  selector: 'iikigai-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'iikigai';

  menu = MENU_ITEMS;

  constructor(private sidebarService: NbSidebarService) { }

  toggleMainSidebar() {
    // will toggle the main sidebar , set the first param to false if we want
    // not the compact view
    this.sidebarService.toggle(true, 'main');
  }

}
