<nb-layout style="padding: 0px !important">
    <nb-layout-header subheader style="background-color:white;">

        <nb-actions size="medium">
            <nb-action icon="bell-outline" badgeDot badgePosition="top right" badgeStatus="danger">
            </nb-action>
            <nb-action icon="person-outline" badgeDot badgePosition="top start" badgeStatus="warning">
            </nb-action>
            <nb-action icon="email-outline" badgeDot badgePosition="top left" badgeStatus="danger">
            </nb-action>
            <nb-action icon="calendar-outline" badgeDot badgePosition="top right" badgeStatus="info">
            </nb-action>
        </nb-actions>
    </nb-layout-header>

    <nb-layout-column>

        <nb-card accent="info">
            <nb-card-body>
                <div>

                    <button nbButton hero status="primary">Primary</button>
                    <button nbButton hero status="success">Success</button>
                    <button nbButton hero status="info">Info</button>
                    <button nbButton hero status="warning">Warning</button>
                    <button nbButton hero status="danger">Danger</button>

                    <button nbButton outline status="primary">Primary</button>
                    <button nbButton outline status="success">Success</button>
                    <button nbButton outline status="info">Info</button>
                    <button nbButton outline status="warning">Warning</button>
                    <button nbButton outline status="danger">Danger</button>

                    <button nbButton status="primary">
                        <nb-icon icon="star"></nb-icon>Primary
                    </button>
                    <button nbButton status="success">Success<nb-icon icon="star"></nb-icon></button>
                    <button nbButton status="danger">
                        <nb-icon icon="star"></nb-icon>
                    </button>
                    <button nbButton ghost status="danger">
                        <nb-icon icon="star"></nb-icon>
                    </button>
                    <hr>


                </div>
                <div>
                    <button nbButton status="info" (click)="addDevice()">Add device<nb-icon icon="plus"></nb-icon>
                    </button>
                </div>
            </nb-card-body>

            <nb-card>

            </nb-card>



        </nb-card>

        <nb-card accent="primary">
            <nb-card-body>
                <ngx-chat></ngx-chat>
                <div>
                    <button nbButton status="primary" (click)="addDevice()">Add device<nb-icon icon="plus"></nb-icon>
                    </button>
                </div>
            </nb-card-body>
        </nb-card>
    </nb-layout-column>
</nb-layout>

<nb-layout-footer>
    Footer
</nb-layout-footer>