import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ot-events',
  template: `
    <p>
      events works!
    </p>
  `,
  styleUrls: ['./events.component.scss']
})
export class EventsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
