import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Subscription } from '@influxdata/influxdb-client';
import { IikigaiService } from '../../../api/services';



@Component({
  selector: 'ot-devices',
  templateUrl: './devices.component.html',
  styleUrls: ['./devices.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DevicesComponent implements OnInit {
  iikigaiBackendSubscription: Subscription;

  constructor(private iibSerice: IikigaiService) { }

  ngOnInit(): void {
  }

  addDevice(): void {

    console.log('Add device called');
    this.iikigaiBackendSubscription = this.iibSerice.healthGetDataApiV1HealthGet().subscribe({
      next: (v) => console.log(v),
      error: (e) => console.error(e),
      complete: () => console.info('complete')
    });
  }

  ngOnDestroy(): void {
    this.iikigaiBackendSubscription.unsubscribe();
  }
}
