import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { OpenthreadDashboardRoutingModule } from './openthread-dashboard-routing.module';
import { DevicesComponent } from './pages/devices/devices.component';
import { EventsComponent } from './pages/events/events.component';
import { NbActionsModule, NbButtonModule, NbCardModule, NbChatModule, NbIconModule, NbLayoutModule } from '@nebular/theme';
import { ChatComponent } from './pages/devices/chat/chat.component';
// import { HeaderComponent } from '../@theme/components';


@NgModule({
  declarations: [
    DevicesComponent,
    EventsComponent,
    ChatComponent,
    // HeaderComponent,
  ],
  imports: [
    CommonModule,
    OpenthreadDashboardRoutingModule,
    NbCardModule,
    NbActionsModule,
    NbLayoutModule,
    NbButtonModule,
    NbIconModule,
    NbChatModule
  ],
  exports: [
    DevicesComponent,
    EventsComponent
  ],
  providers: []
})
export class OpenthreadDashboardModule { }

// to generate component 
// ng generate @schematics/angular:component --name=devices --project=iikigai --module=openthread-dashboard --style=scss --export --inlineTemplate --path=apps/iikigai/src/app/openthread-dashboard/pages --prefix=ot --skipTests
