import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TodosComponent } from './todos/todos.component';
import { HelloIikigaiComponent } from './hello-iikigai/hello-iikigai.component';
import { HeartRateComponent } from './heart-rate/heart-rate.component';


@NgModule({
  imports: [CommonModule],
  declarations: [
    TodosComponent,
    HelloIikigaiComponent,
    HeartRateComponent
  ],
  exports: [
    TodosComponent,
    HelloIikigaiComponent,
    HeartRateComponent
  ],
})
export class UiModule { }
