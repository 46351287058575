import { Component, OnInit } from '@angular/core';
// import { InfluxService } from '@iikigai/data';


@Component({
  selector: 'iikigai-openthread-dashboard',
  templateUrl: './openthread-dashboard.component.html',
  styleUrls: ['./openthread-dashboard.component.scss']
})
export class OpenthreadDashboardComponent implements OnInit {

  // constructor(private db: InfluxService) { }

  ngOnInit(): void {
  }

}
